<script>
export default {
  name: "HeadingDynamicSize",
  functional: true,
  props: {
    level: {
      type: String,
      default: "2",
      validator: (value) => parseInt(value) > 0 && parseInt(value) < 7,
    },
  },
  render(h, context) {
    return h(`h${context.props.level}`, context.data, context.children);
  },
};
</script>
