<template>
  <div :class="`footer ${type}__footer`" :style="styles">{{ product }}</div>
</template>

<script>
export default {
  name: "CompanySchemeFooter",
  props: {
    type: {
      type: String,
      require: true,
      validator: (value) =>
        ["company", "department", "division", "service"].includes(value),
    },
    product: {
      type: String,
    },
    color: {
      type: String,
      require: false,
    },
  },
  computed: {
    styles() {
      return this.color ? { backgroundColor: this.color } : null;
    },
  },
};
</script>
