<template>
  <div class="container">
    <h2>Оргструктура</h2>
    <CompanyStructure :data="organizationsList" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CompanyStructure from "@/components/CompanyStructure";

export default {
  name: "CompanyStructurePage",
  components: {
    CompanyStructure,
  },
  async created() {
    await this.loadOrganizationsList();
  },
  computed: {
    ...mapState({
      organizationsList: (state) =>
        state.companyStructureStore.organizationsList,
    }),
  },
  methods: {
    ...mapActions({
      loadOrganizationsList: "companyStructureStore/loadOrganizationsList",
    }),
  },
};
</script>

<style lang="scss" scope>
.container {
  height: 100vh;
}
</style>
