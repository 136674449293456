<template>
  <div class="service" :class="{ 'service--disabled': !data.is_active }">
    <div class="service-info">
      <h5 class="service__heading">{{ data.name }}</h5>
      <div
        v-for="employee of data.service_employees"
        :key="employee.id"
        class="service__employees"
      >
        <p class="service__employees-label">{{ employee.name }}:</p>
        <p class="service__employees-names">
          {{ employeesString(employee) }}
        </p>
      </div>
    </div>
    <ul class="service__goals">
      <li v-for="goal of data.goals" :key="goal.id" class="service__goals-item">
        {{ goal.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CompanySchemeServiceBlock",
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    employeesString(heads) {
      return heads.employees.map((employee) => employee.full_name).join(", ");
    },
  },
};
</script>
