<template>
  <div ref="container" class="company-scheme">
    <div
      v-if="data"
      ref="company"
      class="company"
      :class="{ 'company--zoomed': isRemotely }"
    >
      <Header
        type="company"
        :name="data.name"
        :heads="data.owners"
        :product="data.product"
      />
      <div class="children-block company__children">
        <div
          v-for="department of data.departments"
          :key="department.id"
          class="department"
        >
          <Header
            type="department"
            :name="department.name"
            :title="department.head_position_title"
            :heads="department.heads"
            :color="department.color"
          />
          <div class="children-block department__children">
            <div
              v-for="division of department.divisions"
              :key="division.id"
              class="division"
            >
              <Header
                type="division"
                :name="division.name"
                :title="division.head_position_title"
                :heads="division.heads"
                :color="department.color"
              />
              <div class="division__children">
                <div class="service__header">
                  <div class="service__header-item">
                    Участок / офис / служба
                  </div>
                  <div class="service__header-item">Функции</div>
                </div>
                <div
                  class="service__body"
                  :style="`background-color: ${department.color}`"
                >
                  <ServiceBlock
                    v-for="service of division.services"
                    :key="service.id"
                    :data="service"
                  />
                </div>
              </div>
              <Footer
                type="division"
                :product="division.product"
                :color="department.color"
              />
            </div>
          </div>
          <Footer
            type="department"
            :product="department.product"
            :color="department.color"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Hierarchy } from "@/assets/dataStructs.js";
import Panzoom from "@panzoom/panzoom";
import Header from "@/components/CompanyStructure/Header.vue";
import Footer from "@/components/CompanyStructure/Footer.vue";
import ServiceBlock from "@/components/CompanyStructure/ServiceBlock.vue";

export default {
  name: "CompanyStructure",
  components: {
    Header,
    Footer,
    ServiceBlock,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      isRemotely: false,
    };
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.alignDivisionFooters();
        this.drawLinks();
        this.initZoom();
      });
    },
  },
  methods: {
    initZoom() {
      if (!this.data) {
        return;
      }

      const panzoom = Panzoom(this.$refs.company, {
        minScale: 0.1,
        maxScale: 2,
      });

      const containerHalfWidth =
        this.$refs.container.getBoundingClientRect().width / 2;
      const schemeHalfWidth =
        -this.$refs.company.getBoundingClientRect().width / 2 +
        containerHalfWidth;
      setTimeout(() => {
        panzoom.pan(schemeHalfWidth, 0);
      });

      this.$refs.company.addEventListener("wheel", panzoom.zoomWithWheel);
      this.$refs.company.addEventListener("panzoomzoom", (event) => {
        const { scale } = event.detail;
        if (scale < 0.5) {
          this.isRemotely = true;
        } else {
          this.isRemotely = false;
        }
      });
    },
    drawLinks() {
      const headersSelector = ".header";
      const parentLeft = this.getContainerLeft();
      const elements = document.querySelectorAll(headersSelector);
      this.hierarchy(elements, parentLeft).drawLinks(this.$refs.company);
    },
    getContainerLeft() {
      const block = document.querySelector(".container");
      const left = block.getBoundingClientRect().left;
      const [paddingLeft] = Hierarchy.getStyleValueByElement(
        block,
        ["padding-left"],
        parseInt
      );
      return left + paddingLeft;
    },
    alignDivisionFooters() {
      let maxHeight = 0;
      let prevHeight = maxHeight;
      let hasBelow = false;

      const footers = document.querySelectorAll(".division__footer");
      footers.forEach((footer) => {
        if (!hasBelow && prevHeight !== 0 && prevHeight !== maxHeight) {
          hasBelow = true;
        }
        prevHeight = maxHeight;
        maxHeight = Math.max(maxHeight, footer.getBoundingClientRect().height);
      });

      if (hasBelow) {
        footers.forEach((footer) => {
          footer.style.height = maxHeight + "px";
        });
      }
    },
    hierarchy(elements, parentLeft) {
      return new Hierarchy({ correction: parentLeft }).init(elements);
    },
  },
};
</script>

<style lang="scss" scope>
@import "@/assets/css/variables.scss";

.company-scheme {
  width: calc(100vw - 220px);
  height: 100%;
}

.company--zoomed {
  filter: blur(1px);
}

.header {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100%;
  min-height: 200px;
  border: 1px solid $erp-dark-color;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  padding: 10px;
  text-align: center;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  height: auto;
  border: 1px solid $erp-dark-color;
  padding: 12px 16px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.children-block {
  display: flex;
  gap: 20px;
}

.company {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
}

.company__header {
  max-width: 1000px;
  background-color: $erp-dark-color;
  color: #cad0d4;
  &-name {
    text-transform: uppercase;
    font-size: 34px;
  }
  &-heads-label {
    text-transform: uppercase;
    font-size: 24px;
  }
  &-heads-people {
    font-size: 24px;
  }
  &-product {
    padding: 14px 20px;
  }
}

.company__children {
  gap: 100px;
}

.company__footer {
  background-color: $erp-dark-color;
  color: #cad0d4;
  font-size: 32px;
}

.department__children {
  flex-grow: 1;
}

.department__footer {
  margin-top: auto;
  align-self: stretch;
}

.department__header {
  font-size: 24px;
  &-heads-label {
    text-transform: uppercase;
  }
}

.department {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: min-content;
}

.division {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-bottom: 20px;
}

.service,
.service__header {
  display: grid;
  grid-template-columns: 200px 1fr;
}

.service__header {
  margin-bottom: 14px;
  color: #b1b1b1;
}

.service__header-item {
  text-align: center;
}

.service {
  border: 1px solid $erp-dark-color;
  margin-bottom: 10px;
  &--disabled {
    background-color: $erp-non-editable-bg-color;
  }
}

.service-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid $erp-dark-color;
  padding: 6px;
}

.service__employees:not(:last-child) {
  margin-bottom: 20px;
}

.service__employees-label {
  font-style: italic;
}

.service__heading {
  text-transform: uppercase;
}

.service__body {
  height: 100%;
}

.division__children {
  flex-grow: 1;
}

.division__header {
  margin-bottom: 30px;
  &-name {
    text-transform: uppercase;
    font-size: 18px;
  }
  &-heads-label {
    text-transform: uppercase;
  }
}

.division__footer {
  margin-top: auto;
}

.service__goals {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 6px 0;

  &-item {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 6px;
    &:not(:first-child) {
      border-top: 1px solid $erp-dark-color;
    }
  }
}

.svg-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
</style>
