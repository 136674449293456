<template>
  <div :class="`header ${type}__header`" :style="styles">
    <HeadingDynamicSize :level="level" :class="`${type}__header-name`">
      {{ name }}
    </HeadingDynamicSize>
    <div :class="`${type}__header-heads`">
      <p :class="`${type}__header-heads-label`">{{ title }}:</p>
      <p :class="`${type}__header-heads-people`">{{ headsString }}</p>
    </div>
    <div :class="`${type}__header-product`">{{ product }}</div>
  </div>
</template>

<script>
import HeadingDynamicSize from "@/components/HeadingDynamicSize.vue";

export default {
  name: "CompanySchemeHeader",
  components: {
    HeadingDynamicSize,
  },
  props: {
    type: {
      type: String,
      require: true,
      validator: (value) =>
        ["company", "department", "division"].includes(value),
    },
    title: {
      type: String,
      default: "Основатели",
    },
    name: String,
    product: String,
    heads: Array,
    color: String,
  },
  computed: {
    headsString() {
      return this.heads.map((employee) => employee.full_name).join(", ");
    },
    styles() {
      return this.color ? { backgroundColor: this.color } : null;
    },
    level() {
      const LEVELS = {
        company: "2",
        department: "3",
        division: "4",
      };
      return LEVELS[this.type];
    },
  },
};
</script>
